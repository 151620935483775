<template>
  <div>
    <NavMenu />
    <div class="container-xxl content-car pb-5">
      <router-view />
    </div>
    <footer class="footer mt-auto py-3 bg-light fixed-bottom">
      <div class="container">
        <span class="text-muted">© Ttracking.us {{ currentYear }}</span>
      </div>
    </footer>
  </div>
  <ScrollToTopButton />
</template>

<script>
import NavMenu from "/src/components/NavMenu.vue";
import ScrollToTopButton from "/src/components/ScrollToTopButton.vue";
export default {
  name: "main-layout",
  components: {
    NavMenu,
    ScrollToTopButton,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  methods: {
    setTitle(to) {
      if (to.params.vin) {
        document.title = `VIN: ${to.params.vin} | Ttracking`;
      } else {
        document.title = to.meta.title || "Tracking";
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.setTitle(to);
    next();
  },
  created() {
    this.setTitle(this.$route);
  },
  watch: {
    $route(to) {
      this.setTitle(to);
    },
  },
};
</script>
<style>
.container-xxl {
  margin-top: -60px;
}
</style>
