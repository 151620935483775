<template>
  <div class="container min-vh-100">
    <div class="text-end">
      <div
        class="btn-group"
        role="group"
        aria-label="Button group with nested dropdown"
      >
        <router-link to="/my-cars" class="h-link nav-link">
          <i class="bi bi-star-fill"></i>
          {{ $localize("My Cars", $store.getters.getLang) }}
        </router-link>
        <div class="btn-group" role="group">
          <button
            id="btnGroupDrop1"
            type="button"
            class="h-link nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="bi bi-translate"></i>
            <span v-if="lg == 'ua'">Ukrainian</span>
            <span v-else-if="lg == 'pl'">Polish</span>
            <span v-else-if="lg == 'ru'">Russian</span>
            <span v-else>English</span>
          </button>
          <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
            <SelectLang />
          </ul>
        </div>
      </div>
    </div>
    <router-view />
  </div>
  <footer class="footer mt-auto py-3 bg-light fixed-bottom">
    <div class="container">
      <span class="text-muted">© Ttracking.us {{ currentYear }}</span>
    </div>
  </footer>
</template>
<script>
import SelectLang from "@/components/SelectLang.vue";
export default {
  name: "empty-layout",
  data: () => ({
    lg: "en",
    currentYear: new Date().getFullYear(),
  }),
  components: {
    SelectLang,
  },
  mounted() {
    try {
      this.lg = this.$store.getters.getLang;
      this.$store.watch(
        () => this.$store.getters.getLang,
        (newLang) => {
          this.lg = newLang;
        }
      );
    } catch (error) {
      console.error("Error in mounted:", error);
    }
  },
  watch: {
    $route(to) {
      document.title = to.meta.title || "Home | Ttracking";
    },
  },
};
</script>
<style>
.bi-translate {
  margin-right: 5px;
}
</style>
